import { Typography, Paper } from '@material-ui/core'
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from '@material-ui/lab'
import { ActiveRegisteredDot, CompleteDot } from './Dots'

function NormalRegistration({ classes, callTime, insuranceStatus }) {
  return (
    <>
      <Paper elevation={3} className={classes.activeParagraph}>
        <Typography variant='h6' component='h1' align='center' gutterBottom={true}>
          Registered
        </Typography>
        <Typography paragraph={true}>
          Thank you for choosing MainStreet! We have received your registration and secured your spot in line.
        </Typography>
        <Typography gutterBottom={true}>
          Next, we will send you a text when we are ready for you to check in at the clinic. Please be prepared to
          arrive at the clinic within 30 minutes of receiving that text, as your place may be lost if you do not check
          in within 30 minutes of being notified to check in. Please wait to check in at the clinic until you receive
          the text to check in.
        </Typography>
      </Paper>
      {insuranceStatus === 'unresolved' && callTime.position <= 0 ? (
        <Paper elevation={3} className={classes.paper}>
          <Typography variant='h6' color='primary' align='center'>
            We will update your wait time after we hear back from your insurance company regarding your copay today.
            Check back soon!
          </Typography>
        </Paper>
      ) : (
        <Paper elevation={3} className={classes.paper}>
          <Typography variant='h6' color='primary' align='center'>
            ESTIMATED TIME FROM NOW TO BE NOTIFIED TO CHECK IN AT THE KIOSK:
          </Typography>
          {callTime.totalMinutes > callTime.nextInLineThreshold ? (
            <Typography variant='h4' color='primary' align='center'>
              {callTime.hours > 0
                ? `${callTime.hours} hour${callTime.hours > 1 ? 's' : ''} and ${callTime.minutes} minutes`
                : `${callTime.minutes} minutes`}
            </Typography>
          ) : (
            <Typography variant='h4' color='primary' align='center'>
              {` Less than ${callTime.nextInLineThreshold} minutes`}
            </Typography>
          )}
        </Paper>
      )}
    </>
  )
}

function EarlyRegistration({ classes }) {
  return (
    <Paper elevation={3} className={classes.activeParagraph}>
      <Typography variant='h6' component='h1' align='center' gutterBottom={true}>
        Registered
      </Typography>
      <Typography paragraph={true}>
        Thank you for choosing MainStreet! We have received your registration and secured your spot in line.
      </Typography>
      <Typography gutterBottom={true}>
        Please arrive at the clinic to check in as soon as the clinic opens. If you do not check in with the clinic
        within 5 minutes of opening, your place in line may be lost.
      </Typography>
    </Paper>
  )
}

export default function RegisteredTimeItem({
  classes,
  patientState,
  queueState,
  registrationTimeState,
  callTime,
  insuranceStatus,
}) {
  return (
    <TimelineItem>
      <TimelineOppositeContent
        style={{
          flex: 0.0,
          padding: 0,
        }}
      >
        <Typography variant='h6' component='h1' color='textSecondary'></Typography>
      </TimelineOppositeContent>

      <TimelineSeparator>
        {patientState === 'registered' ? <ActiveRegisteredDot /> : <CompleteDot />}

        <TimelineConnector className={patientState === 'registered' ? classes.activeTail : classes.inactiveTail} />
      </TimelineSeparator>
      <TimelineContent>
        {patientState !== 'registered' && (
          <Typography variant='h6' component='h1' color='textSecondary'>
            Registered
          </Typography>
        )}
        {patientState === 'registered' &&
          (queueState === 'front' && registrationTimeState === 'beforeOpen' ? (
            <EarlyRegistration classes={classes} />
          ) : (
            <NormalRegistration classes={classes} callTime={callTime} insuranceStatus={insuranceStatus} />
          ))}
      </TimelineContent>
    </TimelineItem>
  )
}
