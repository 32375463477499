import { TimelineDot } from '@material-ui/lab'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import AssignmentIcon from '@material-ui/icons/Assignment'
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk'
import HowToRegIcon from '@material-ui/icons/HowToReg'

export const ActiveRegisteredDot = () => {
  return (
    <TimelineDot color='secondary'>
      <AssignmentIcon />
    </TimelineDot>
  )
}

export const ActiveArrivalDot = () => {
  return (
    <TimelineDot color='secondary'>
      <DirectionsWalkIcon />
    </TimelineDot>
  )
}

export const ActiveCheckinDot = () => {
  return (
    <TimelineDot color='secondary'>
      <HowToRegIcon />
    </TimelineDot>
  )
}

export const IncompleteCheckInDot = () => {
  return (
    <TimelineDot color='primary' variant='outlined'>
      <HowToRegIcon color='primary' />
    </TimelineDot>
  )
}

export const IncompleteArrivalDot = () => {
  return (
    <TimelineDot color='primary' variant='outlined'>
      <DirectionsWalkIcon color='primary' />
    </TimelineDot>
  )
}

export const CompleteDot = () => {
  return (
    <TimelineDot color='grey'>
      <CheckCircleIcon />
    </TimelineDot>
  )
}

export const BounceDot = () => {
  return (
    <TimelineDot style={{ borderColor: 'transparent', backgroundColor: '#9c3144' }}>
      <ErrorOutlineIcon />
    </TimelineDot>
  )
}

export const WarningDot = () => {
  return (
    <TimelineDot style={{ borderColor: 'transparent', backgroundColor: '#d2a517' }}>
      <DirectionsWalkIcon />
    </TimelineDot>
  )
}
