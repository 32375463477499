import { Typography, Paper } from '@material-ui/core'
import { TimelineItem, TimelineSeparator, TimelineContent, TimelineOppositeContent } from '@material-ui/lab'

import { ActiveCheckinDot, BounceDot, IncompleteCheckInDot } from './Dots'

export default function CheckedInTimeItem({ patientState, classes, calledToClinicNearClosing, closeTime }) {
  return (
    <TimelineItem>
      <TimelineOppositeContent
        style={{
          flex: 0.0,
          padding: 0,
        }}
      >
        <Typography variant='h6' component='h1' color='textSecondary'></Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        {patientState !== 'checkedIn' && patientState !== 'bouncedCheckIn' ? (
          <IncompleteCheckInDot />
        ) : patientState === 'bouncedCheckIn' ? (
          <BounceDot />
        ) : (
          <ActiveCheckinDot />
        )}
      </TimelineSeparator>
      <TimelineContent>
        {(patientState === 'registered' || patientState === 'ready') && (
          <Typography variant='h6' component='h1' color='textSecondary'>
            Check In
          </Typography>
        )}
        {patientState === 'checkedIn' &&
          (calledToClinicNearClosing ? (
            <Paper elevation={3} className={classes.activeParagraph}>
              <Typography variant='h6' component='h1' align='center' gutterBottom={true}>
                You're Checked In!
              </Typography>
              <Typography paragraph={true}>
                There may be a short wait as we process your information and prepare an exam room. Please remain in the
                waiting room. Don't worry - we will bring you back as quickly as we can! We appreciate your patience.
              </Typography>
              <Typography gutterBottom={true}>
                When we are ready, we will call your name to have you come back with us. In the meantime, please help
                yourself to a complimentary coffee or beverage.
              </Typography>
            </Paper>
          ) : (
            <Paper elevation={3} className={classes.activeParagraph}>
              <Typography variant='h6' component='h1' align='center' gutterBottom={true}>
                You're Checked In!
              </Typography>
              <Typography paragraph={true}>
                There may be a short wait as we process your information and prepare an exam room. Please remain in the
                waiting room. Don't worry - we will bring you back as quickly as we can! We appreciate your patience.
              </Typography>
              <Typography gutterBottom={true}>
                When we are ready, we will call your name to have you come back with us. In the meantime, please help
                yourself to a complimentary coffee or beverage.
              </Typography>
            </Paper>
          ))}
        {patientState === 'bouncedCheckIn' &&
          (calledToClinicNearClosing ? (
            <Paper elevation={3} className={classes.bounceParagraph}>
              <Typography variant='h6' component='h1' align='center' gutterBottom={true}>
                Clinic Closed
              </Typography>
              <Typography>
                We're sorry we missed you! Our clinic closed at {closeTime} today and we require all patients to arrive
                to the clinic lobby before closing for the safety of our patients and staff. Please register online
                tomorrow morning to be seen at the clinic.
              </Typography>
            </Paper>
          ) : (
            <Paper elevation={3} className={classes.bounceParagraph}>
              <Typography variant='h6' component='h1' align='center' gutterBottom={true}>
                We're Sorry
              </Typography>
              <Typography>
                We're sorry, you missed your check in window. Please come to the clinic to see if we can accommodate
                you.
              </Typography>
            </Paper>
          ))}
      </TimelineContent>
    </TimelineItem>
  )
}
