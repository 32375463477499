import { Typography, Paper } from '@material-ui/core'
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from '@material-ui/lab'
import { ActiveArrivalDot, CompleteDot, IncompleteArrivalDot, WarningDot } from './Dots'

function NormalRegistration({ classes, checkInCode }) {
  return (
    <Paper elevation={3} className={classes.activeParagraph}>
      <Typography variant='h6' component='h1' align='center' gutterBottom={true}>
        You're ready to check in at the kiosk!
      </Typography>
      <Typography paragraph={true}>Please arrive within 30 minutes to keep your spot in line.</Typography>
      <Typography paragraph={true}>
        Once you arrive at the clinic, use your check-in code or QR code to check in on the kiosk.
      </Typography>
      <Typography gutterBottom={true}>Your check-in code: {checkInCode}</Typography>
    </Paper>
  )
}

function CalledToClinicNearClosing({ classes, checkInCode, closeTime }) {
  return (
    <Paper elevation={3} className={classes.activeParagraph}>
      <Typography variant='h6' component='h1' align='center' gutterBottom={true}>
        Please arrive at the clinic before close at {closeTime}!
      </Typography>
      <Typography paragraph={true}>
        For the safety of our staff and patients, all patients that have registered to be seen for the day must arrive
        to the clinic lobby before closing time of {closeTime}.
      </Typography>
      <Typography paragraph={true}>
        We are happy to save your spot in line and be able to fit you into our busy day.
      </Typography>
      <Typography gutterBottom={true}>
        Please enter the clinic lobby by {closeTime} and check in at the kiosk. To check in, simply scan your QR code or
        enter your check-in code: {checkInCode}
      </Typography>
      <Typography paragraph={true}>
        We will call you back as soon as we are ready for you. We apologize for the wait - we are staying late tonight
        so that we can take care of you today!
      </Typography>
    </Paper>
  )
}

function EarlyRegistration({ classes, checkInCode }) {
  return (
    <Paper elevation={3} className={classes.activeParagraph}>
      <Typography variant='h6' component='h1' align='center' gutterBottom={true}>
        You're ready to check in at the kiosk!
      </Typography>
      <Typography paragraph={true}>
        Once you arrive at the clinic, use your check-in code or QR code to check in on the kiosk.
      </Typography>
      <Typography gutterBottom={true}>Your check-in code: {checkInCode}</Typography>
    </Paper>
  )
}

function WarnedParagraph({ classes, checkInCode }) {
  return (
    <Paper elevation={3} className={classes.warnedParagraph}>
      <Typography variant='h6' component='h1' align='center' gutterBottom={true}>
        Ready for you to check in!
      </Typography>
      <Typography paragraph={true}>
        It has been 30 minutes since you were notified. If you haven't checked in at the clinic, please do so now to
        save your place in line.
      </Typography>
      <Typography gutterBottom={true}>Your check-in code: {checkInCode}</Typography>
    </Paper>
  )
}

function WarnedNearClosing({ classes, checkInCode, closeTime }) {
  return (
    <Paper elevation={3} className={classes.warnedParagraph}>
      <Typography variant='h6' component='h1' align='center' gutterBottom={true}>
        Please arrive to the clinic lobby now!
      </Typography>
      <Typography paragraph={true}>
        Our clinic closes at {closeTime} today. Please arrive to the clinic lobby before closing and check in at the
        kiosk with code: {checkInCode}
      </Typography>
    </Paper>
  )
}

export default function ArrivedTimeItem({
  patientState,
  queueState,
  registrationTimeState,
  classes,
  isWarned,
  checkInCode,
  calledToClinicNearClosing,
  closeTime,
}) {
  const returnArrivalState = () => {
    if (calledToClinicNearClosing && isWarned)
      return <WarnedNearClosing classes={classes} checkInCode={checkInCode} closeTime={closeTime} />
    if (calledToClinicNearClosing)
      return <CalledToClinicNearClosing classes={classes} checkInCode={checkInCode} closeTime={closeTime} />
    if (isWarned) return <WarnedParagraph classes={classes} checkInCode={checkInCode} />
    if (queueState === 'front' && registrationTimeState === 'beforeOpen')
      return <EarlyRegistration classes={classes} checkInCode={checkInCode} />

    return <NormalRegistration classes={classes} checkInCode={checkInCode} />
  }
  return (
    <TimelineItem>
      <TimelineOppositeContent
        style={{
          flex: 0.0,
          padding: 0,
        }}
      >
        <Typography variant='h6' component='h1' className={classes.activeStatus}></Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        {patientState === 'registered' ? (
          <IncompleteArrivalDot />
        ) : patientState === 'checkedIn' || patientState === 'bouncedCheckIn' ? (
          <CompleteDot />
        ) : isWarned ? (
          <WarningDot />
        ) : (
          <ActiveArrivalDot />
        )}

        <TimelineConnector
          className={
            patientState === 'ready' && isWarned
              ? classes.warningTail
              : patientState === 'ready' && !isWarned
              ? classes.activeTail
              : classes.inactiveTail
          }
        />
      </TimelineSeparator>
      <TimelineContent>
        {patientState === 'registered' && (
          <Typography variant='h6' component='h1' color='textSecondary'>
            Arrive
          </Typography>
        )}
        {patientState === 'ready' && returnArrivalState()}
        {patientState === 'checkedIn' && (
          <Typography variant='h6' component='h1' color='textSecondary'>
            Arrived
          </Typography>
        )}
        {patientState === 'bouncedCheckIn' && (
          <Typography variant='h6' component='h1' color='textSecondary'>
            Missed
          </Typography>
        )}
      </TimelineContent>
    </TimelineItem>
  )
}
