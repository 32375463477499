import React, { useEffect, useState } from 'react'
import { makeStyles, Typography, LinearProgress } from '@material-ui/core'
import { Timeline } from '@material-ui/lab'
import io from 'socket.io-client'
import RegisteredTimeItem from './RegisteredTimeItem'
import ArrivedTimeItem from './ArrivedTimeItem'
import CheckedInTimeItem from './CheckedInTimeItem'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
const { ServiceBusClient } = require('@azure/service-bus')

// connection string to your Service Bus namespace
const connectionString = process.env.REACT_APP_SERVICE_BUS

// name of the queue
const queueName = 'tracker-data'

const queryClient = new QueryClient()

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  activeTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  warningTail: {
    backgroundColor: theme.palette.warning.main,
  },
  inactiveTail: {},
  activeParagraph: {
    backgroundColor: theme.palette.secondary.main,
    padding: '6px 16px',
    color: 'white',
  },
  bounceParagraph: {
    backgroundColor: theme.palette.error.main,
    padding: '6px 16px',
    color: 'white',
  },
  warnedParagraph: {
    backgroundColor: theme.palette.warning.main,
    padding: '6px 16px',
  },

  appBody: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '90vw',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '50vw',
      margin: '0 auto',
    },
  },
}))

export default function Apper() {
  return (
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  )
}

setTimeout(() => {
  window.location.reload()
}, 3600000) //an hour

function App() {
  const classes = useStyles()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const userId = urlParams.get('id')
  const [locationAllowed, setLocationAllowed] = useState(true)

  const redirectBack = (url) => {
    window.location.href = url
  }

  const [response, setResponse] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const status = response?.patientStatus

  function useGeolocation() {
    return useQuery(
      ['geo', {garbage: status}], //give it some garbage so it will magically pick up status var
      (garbage) => {
        // geolocate(userId, response?.patientStatus)

        const id = userId

        console.log({ id, status, response })
        if (!status) return
        navigator.geolocation.getCurrentPosition(
          async function (position) {
            if (!locationAllowed) setLocationAllowed(true)
            const coords = position.coords
            const userAgent = navigator.userAgent
            console.log({ coords })
            console.log({ userAgent })
            console.log('Latitude is :', coords.latitude)
            console.log('Longitude is :', coords.longitude)
            await logGeolocation({
              id,
              userAgent,
              status,
              tracking: {
                allowed: true,
                latitude: coords.latitude,
                longitude: coords.longitude,
                accuracy: coords.accuracy,
              },
            })
          },
          async function (err) {
            if (!locationAllowed) return
            setLocationAllowed(false)
            console.log({ err })
            await logGeolocation({
              id,
              userAgent: navigator.userAgent,
              status,
              tracking: {
                allowed: false,
              },
              err: { code: err.code, message: err.message },
            })
          }
        )
      },
      {
        retry: false,
        cacheTime: 'Infinity',
        refetchOnMount: false,
        refetchOnWindowFocus: true,
        refetchOnReconnect: false,
        refetchInterval: 300000,
        refetchIntervalInBackground: true,
      }
    )
  }

  useGeolocation()

  useEffect(() => {
    if (navigator && navigator.geolocation) {
      console.log('Available')
    } else {
      console.log('Not Available')
    }

    console.log('reality check')
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT, {
      transports: ['websocket', 'polling'],
      query: {
        registrationId: userId,
      },
    })
    socket.on('connect', () => {
      console.log(socket.connected)
    })

    socket.on('status-updated', (data) => {
      setResponse(data)
      console.log(data)
      setIsLoading(false)
      console.log('status-updated')
    })
    return () => socket.disconnect()
  }, [])

  useEffect(() => {
    if (response?.patientStatus === 'cancelled' || response?.patientStatus === 'completed') {
      redirectBack(process.env.REACT_APP_REDIRECT_URL + `?waiting=offsite&clinic=${response?.clinic?.key}`)
    }
  }, [response])

  return (
    <div className={classes.appBody}>
      {isLoading ? (
        <>
          <LinearProgress />
          <Typography color='primary' variant='h4' align='center' style={{ marginTop: '2vh' }}>
            Please wait while we gather your information.
          </Typography>
          <Typography color='primary' variant='h5' align='center' style={{ marginTop: '2vh' }}>
            If you still see the screen after 1 minute, please refresh the page.
          </Typography>
          <Typography color='primary' variant='h5' align='center' style={{ marginTop: '2vh' }}>
            After refreshing, if you still see this screen, please contact the clinic.
          </Typography>
          <Typography color='primary' variant='body2' align='center' style={{ marginTop: '5vh' }}>
            U-GUID: {userId ? `${userId}` : 'NO U-GUID'}
          </Typography>
        </>
      ) : (
        <>
          <Typography color='primary' variant='h3' align='center' style={{ marginTop: '2vh' }}>
            {`${response?.clinic?.name} Clinic`}
          </Typography>
          <Timeline align='left'>
            <RegisteredTimeItem
              classes={classes}
              patientState={response.patientStatus}
              queueState={response.queuePosition}
              registrationTimeState={response.registrationTime}
              callTime={response.callTime}
              insuranceStatus={response.insuranceStatus}
            />

            <ArrivedTimeItem
              classes={classes}
              patientState={response.patientStatus}
              queueState={response.queuePosition}
              registrationTimeState={response.registrationTime}
              isWarned={response.warned}
              checkInCode={response.checkInCode}
              insuranceStatus={response.insuranceStatus}
              calledToClinicNearClosing={response.calledToClinicNearClosing}
              closeTime={response.closeTime}
            />

            <CheckedInTimeItem
              classes={classes}
              patientState={response.patientStatus}
              queueState={response.queuePosition}
              registrationTimeState={response.registrationTime}
              insuranceStatus={response.insuranceStatus}
              calledToClinicNearClosing={response.calledToClinicNearClosing}
              closeTime={response.closeTime}
            />
          </Timeline>
        </>
      )}
    </div>
  )
}

async function logGeolocation(payload) {
  const sbClient = new ServiceBusClient(connectionString)

  const sender = sbClient.createSender(queueName)

  try {
    const message = {
      body: payload,
    }

    await sender.sendMessages(message)

    await sender.close()
  } finally {
    await sbClient.close()
  }
}

// async function logGeolocation(payload) {
//   socket.emit('geo', payload)
// }
