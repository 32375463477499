import React from 'react'
import { render } from 'react-dom'
import Apper from './App'
import * as serviceWorker from './serviceWorker'
import { createTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles'

let baseTheme = createTheme({
  palette: {
    primary: {
      main: '#283e73',
    },

    secondary: {
      main: '#007d57',
    },

    error: {
      main: '#9c3144',
    },

    warning: {
      main: '#d2a517',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Oswald', 'Open Sans'].join(','),
  },

  height: '100%',
})
baseTheme = responsiveFontSizes(baseTheme)

render(
  <React.StrictMode>
    <ThemeProvider theme={baseTheme}>
      <Apper />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
